import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';
import image1 from '../../images/Orthopaedie Praxis Dr Schubert.jpg';
import image2 from '../../images/Orthopaede Schubert Praxis Wartebereich.jpg';
import image3 from '../../images/Orthopaede Schubert Praxis Rezeption.jpg';
import image4 from '../../images/Orthopaede Schubert Praxis Rntgen.jpg';
import image5 from '../../images/Orthopaede Schubert Praxis Bereich.jpg';

import imageSchubert from '../../images/Bild14_Dr_Schubert-min.jpg';
import imageKostka from '../../images/Bild12_Dr_Kostka-min.jpg';

import imageTeam1 from '../../images/Nadine M.-min.jpg';
import imageTeam2 from '../../images/Tabea W.-min.jpg';
import imageTeam3 from '../../images/Irem H.-min.jpg';
import imageTeam4 from '../../images/Suemeyye S.-min.jpg';
import imageTeam5 from '../../images/Paula S.-min.jpg';
import imageTeam6 from '../../images/Rosa S.-min.jpg';

const PraxisPage = () => (
  <Layout headerWhite>
    <SEO title="Praxis | Dr. Kostka - Ästhetik und Integrative Medizin" description="Dr. K. Kostka bietet integrative Medizin und Ästhetik mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓" />
    <Container>
      <Section>
        <Headline h1 subtitle="Dr. Kathrin Kostka">Praxis</Headline>
        <Row>
          <Col xs={12} lg={12}>
            Barrierefrei - Parkhaus direkt am Platz - klimatisierte Räume
            <br />
            <br />
            Unsere moderne Praxis ist nach neuesten baurechtlichen Vorgaben barrierefrei und behindertengerecht entstanden.
            Der Wartebereich ist mit einer Minibar und  HD- Flachbild TV ausgestattet.
            Wir bieten Ihnen ein kostenloses WLAN Netz.
            <br />
            <br />
            Sie parken direkt unter dem Dr-Ruer-Platz im Parkhaus P2 mit 375 Stellplätzen.
          </Col>
        </Row>
      </Section>
    </Container>
    <Section dark>
      <Row noGutters>
        <Col xs={12} lg={6}>
          <div className="fullWidth-col-image" />
        </Col>

        <Col xs={12} lg={6}>
          <div className="fullWidth-col-content right">
            <Headline light>Dr. Kathrin Kostka</Headline>
            <ul>
              <li>Studium der Pharmazie und  Humanmedizin in Pavia, Italien Abschluss in Humanmedizin 1991</li>
              <li>Facharztausbildung zur Anästhesiologin und Notfallmedizinerin in Deutschland Fachärztin für Anästhesiologie 1999</li>
              <li>Facharzttätigkeit seit über 20 Jahren</li>
              <li>Langjährige Weiterbildung in Komplementärmedizin:
                <ul>
                  <li>Naturheilverfahren</li>
                  <li>Homöopathie</li>
                  <li>Traditionelle und  integrative Medizin</li>
                  <li>Ästhetische Medizin</li>
                </ul>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Section>
    <Section>
      <Container>
        <Row>
          <Col xs={12} md={6}>
          <Headline>
          Zertifizierungen
          </Headline>
            <p>
              <ul>
                <li>Ästhetische Medizin</li>
                <li>Ernährungsmedizin</li>
                <li>Schmerztherapie</li>
                <li>Mesotherapie</li>
                <li>Diplom der Österreichischen Gesellschaft für Akupunktur und Auriculotherapie</li>
                <li>Zertifikat Schädelakupunktur nach Yamamoto</li>
              </ul>
            </p>
          </Col>
          <Col xs={12} md={6}>
          <Headline>
            Mitgliedschaften
          </Headline>
          <p>
              <ul>
                <li><b>DGÄ: </b>Deutsche Gesellschaft für Ästhetik e.V.</li>
                <li><b>DGE: </b>Deutsche Gesellschaft für Ernährung e.V.</li>
                <li><b>FACM: </b>Forschungsgruppe Akupunktur</li>
                <li><b>IGOST: </b>Interdisziplinäre Gesellschaft für orthopädische/ unfallchirurgische und allgemeine Schmerztherapie e.V.</li>
                <li><b>DGM: </b>Deutsche Gesellschaft für Mesotherapie e.V.</li>
              </ul>
            </p>
          </Col>
        </Row>
      </Container>
    </Section>
    <AwesomeSlider
      className="fullWidth-slider"
      organicArrows={false}
    >
      <div data-src={image1} />
      <div data-src={image2} />
      <div data-src={image5} />
    </AwesomeSlider>

  </Layout>
);

export default PraxisPage;
